import React, { useEffect } from 'react'
import Scroll from "../components/scroll"
import SEO from "../components/seo"
import PageTitle from '../components/pageTitle'
import PageImage from '../components/pageImage'
import WorksList from '../components/worksList'
import Footer from '../components/footer'
import Smiley from '../components/smiley'
import magnetHover from '../helpers/magnetHover';
import '../styles/pages/works.scss'

const Work = ({ data: { prismic } }) => {

	const dataIntro = prismic.allDo_goods.edges[0].node;
	const dataWorks = prismic.allProjects.edges;

	useEffect(() => {
		magnetHover();
	});

	return (
  	<div className="Works">
			<SEO
				title={dataIntro.page_title[0].text}
			/>
			<Scroll onScroll={() => {}}>
				<div className="Works__Container">
					<div className="Works__Image">
						<PageImage
							image={dataIntro.image}
							imageSharp={dataIntro.imageSharp}
							imageHover={dataIntro.image_hover}
							imageHoverSharp={dataIntro.image_hoverSharp}
						/>
					</div>
					<div className="Works__Right">
						<PageTitle
							top={dataIntro.page_title_top_line[0].text}
							bottom={dataIntro.page_title_bottom_line[0].text}
							topLineOffset={dataIntro.page_title_top_line_offset}
							bottomLineOffset={dataIntro.page_title_bottom_line_offset}
						/>
					</div>
				</div>
				<Smiley />
				<WorksList data={dataWorks} />
				<Footer />
			</Scroll>
  	</div>
	)
}

export default Work

export const worksQuery = graphql`
  query worksQuery {
		prismic {
			allDo_goods {
				edges {
					node {
						page_title
						page_title_top_line_offset
          	page_title_top_line
          	page_title_bottom_line_offset
          	page_title_bottom_line
						image
						imageSharp {
							childImageSharp {
								fluid(maxWidth: 2000) {
									...GatsbyImageSharpFluid
								}
							}
						}
						image_hover
						image_hoverSharp {
							childImageSharp {
								fluid(maxWidth: 2000) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
			allProjects(sortBy:meta_firstPublicationDate_DESC) {
				edges {
					node {
						_meta {
							uid
						}
						title
						note
						hover_image
          	hover_imageSharp {
							childImageSharp {
								fluid(maxWidth: 2000) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
  }
`

